@use "node_modules/@ef-global/web-ui/scss/settings/mixins/breakpoints";

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

@include breakpoints.breakpoint(l) {
  .l_left {
    text-align: left;
  }
  .l_center {
    text-align: center;
  }
  .l_right {
    text-align: right;
  }
}